<template>
  <div>
    <div class="desktop-view">
      <div
        class="
          border
          col-lg-11 col-md-11
          container-md
          justify-content-center
          mt-5
        "
      >
        <CDataTable
          :striped="true"
          :small="true"
          :responsive="true"
          :items="getItems"
          :fields="fields"
          :items-per-page="5"
          sorter
          pagination
        >
          <template #preview="{ item }">
            <td class="py-2">
              <a
                class="hand-icon"
                @click="previewJob(item)"
                :style="'cursor:pointer'"
              >
                <i class="fas fa-eye ml-3" v-c-tooltip="'Preview Job'"></i>
              </a>
            </td>
          </template>
          <template #remove_favo="{ item, index }">
            <td v-if="getItems[index].favo_id" class="py-2">
              <div class="detail-action-item">
                <span
                  class="f-1_5 action position-relative multi-select-icon-div"
                  :style="'cursor:pointer'"
                  @click="onUnFavouriteJob(item)"
                >
                  <i
                    class="fas fa-trash"
                    v-c-tooltip="{
                      content: `Remove Job from Favourites`,
                      placement: 'left',
                    }"
                  ></i>
                </span>
              </div>
            </td>
          </template>
          <template #apply="{ item, index }">
            <td class="py-2" v-if="!getItems[index].apply">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="onApplyJob(item)"
                >Apply Now</CButton
              >
            </td>
            <td class="py-2" v-else>
              <CButton
                square
                size="sm"
                class="btn btn-secondary text-primary mb-3"
                @click="onWithdrawJob(item)"
                >Withdraw</CButton
              >
            </td>
          </template>
        </CDataTable>
      </div>
    </div>
    <div class="mobile-view">
      <div v-if="getItems" class="multi-records">
        <div
          class="top-border d-flex justify-content-between p-2"
          v-for="(item, index) in getItems"
          :key="index"
        >
          <CRow class="d-flex" style="margin-left: 2px">
            <div class="table col col-5">
              <p class="head">Job ID</p>
              <p class="data">{{ item.job_id }}</p>
            </div>
            <div class="table col col-5">
              <p class="head">Job title</p>
              <p class="data">
                {{ item.job_title }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Facility Name</p>
              <p class="data">
                {{ item.facility_name }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Location</p>
              <p class="data">
                {{ item.location }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Country</p>
              <p class="data">
                {{ item.country }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Level</p>
              <p class="data">
                {{ item.level }}
              </p>
            </div>
            <div class="table col col-4">
              <CButton color="primary" class="pl-8" @click="previewJob(item)">
                Preview
              </CButton>
            </div>
            <div class="table col col-4">
              <CButton
                v-if="!getItems[index].apply"
                color="primary"
                class="pl-2"
                @click="onApplyJob(item)"
              >
                Apply Now
              </CButton>
              <CButton
                v-else
                color="btn btn-secondary text-primary"
                class="pl-2"
                @click="onWithdrawJob(item)"
              >
                Withdraw
              </CButton>
            </div>
            <span @click="onUnFavouriteJob(item)" class="center pt-3 px-3">
              <i class="fas fa-trash"></i>
            </span>
          </CRow>
        </div>
        <div v-if="!getItems.length">
          <p
            class="pt-3 text-center"
            :style="{ background: '#cccccc', height: '50px' }"
          >
            No items <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
          </p>
        </div>
      </div>
    </div>
    <JobCardPreviewModal
      v-if="previewModal"
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
    <CModal
      id="applyjobModal"
      :title="modalTitle"
      color="primary"
      centered:false
      :show.sync="successModal"
      >{{ this.modalMessage }}
      <template #footer>
        <span>
          <CButton
            class="ml-2"
            variant="outline"
            color="primary"
            @click="applyModalCallBack(false)"
            >Cancel</CButton
          >
          <CButton
            class="ml-2"
            color="primary"
            @click="applyModalCallBack(true)"
            >Confirm</CButton
          >
        </span>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";

export default {
  name: "FavouriteJobs",
  components: {
    JobCardPreviewModal,
  },
  data() {
    return {
      job: null,
      successModal: false,
      modalTitle: "",
      modalMessage: "",
      previewModal: false,
      isApplyingToJob: false,
      isWithdrawJob: false,
      isUnFavJob: false,
      favoId: null,
      sorter: { external: true, resetable: true },
      fields: [
        { key: "job_id", label: "Job ID", _style: "width:7%" },
        { key: "job_title", label: "Job Title", _style: "width:20%" },
        { key: "facility_name", label: "Facility Name", _style: "width:20%" },
        { key: "location", label: "Location", _style: "width:10%" },
        { key: "country", label: "Country", _style: "width:10%" },
        { key: "level", label: "Level", _style: "width:10%" },
        { key: "preview", label: "Preview" },
        { key: "apply", label: "Apply" },
        { key: "remove_favo", label: "", _style: "width:5%" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCandidateFavJobs", "getRoleScopes"]),
    getItems() {
      return this.getCandidateFavJobs?.length
        ? this.getCandidateFavJobs
            .filter((val) => !val?.ids?.applied_id && val?.ids?.favourite_id)
            .map((val) => ({
              job_uuid: val?.job_uuid,
              job_id: val?.job_id || "--",
              customer_id: val?.customer_uid,
              job_title: val?.job_title || "--",
              facility_name:
                val?.organisation_name || val?.organisation.name || "--",
              location: val?.location_name || val?.location?.location || "--",
              country:
                val?.country_name ||
                val?.location?.country?.country_name ||
                "--",
              level: val?.job?.level_names
                ? val?.job?.level_names?.join(", ")
                : val?.level_names
                ? val?.level_names?.join(", ")
                : "--",
              apply: val?.ids?.applied_id,
              favo_id: val?.ids?.favourite_id,
            }))
        : [];
    },
  },
  mounted() {
    const preferred_type = "favourite";
    this.fetchCandidatePreferredJobs(preferred_type);
  },
  methods: {
    ...mapActions([
      "fetchCandidatePreferredJobs",
      "fetchJobBenefitsAdvertDescByJobId",
      "applyJob",
      "withdrawJob",
      "fetchCandidateAppliedJobs",
      "fetchAppliedJobCount",
      "fetchFavoJobCount",
      "deletePreferredJob",
    ]),
    previewJob(job) {
      if (this.getRoleScopes && this.getRoleScopes.length) {
        this.fetchJobBenefitsAdvertDescByJobId({ job_uuid: job?.job_uuid });
        this.previewModal = true;
      }
    },
    onCancelPreview() {
      this.previewModal = false;
    },
    onApplyJob(job) {
      this.modalTitle = "Apply Job";
      this.job = job;
      this.successModal = true;
      this.isApplyingToJob = true;
      this.modalMessage = `Are you sure you want to apply for this job ?`;
    },
    onWithdrawJob(job) {
      this.job = job;
      this.job_uuid = this.successModal = true;
      this.isWithdrawJob = true;
      this.modalMessage = `Are you sure you want to withdraw from this job ?`;
    },
    onUnFavouriteJob(job) {
      this.modalTitle = "Remove Favourite";
      this.job = job;
      this.favoId = job?.favo_id;
      this.successModal = true;
      this.isUnFavJob = true;
      this.modalMessage = `Are you sure you want to remove this job from Favourites ?`;
    },
    onShow(e, value) {
      if (value.target.textContent === " OK ") {
        const { job_id, job_uuid } = this.job;
        if (this.isApplyingToJob) {
          this.applyJob({ job_id, job_uuid }).then((res) => {
            this.fetchAppliedJobCount();
            this.fetchCandidateAppliedJobs();
            this.fetchFavoJobCount();
          });
          this.isApplyingToJob = false;
        } else if (this.isUnFavJob) {
          let favo_id = this.favoId;
          const preferred_type = "favourite";
          if (favo_id) {
            this.deletePreferredJob({
              job_id,
              job_uuid,
              preferred_id: favo_id,
              preferred_type,
            }).then((res) => {
              this.fetchFavoJobCount();
            });
            this.isUnFavJob = false;
          }
        } else if (isWithdrawJob) {
          this.withdrawJob({ job_id, job_uuid }).then((res) => {
            this.fetchAppliedJobCount();
          });
          this.isWithdrawJob = false;
        }
      } else {
        this.isApplyingToJob = false;
        this.isUnFavJob = false;
        this.isWithdrawJob = false;
      }
    },
    applyModalCallBack(action) {
      if (action) {
        const { job_id, job_uuid } = this.job;
        if (this.isApplyingToJob) {
          this.applyJob({ job_id, job_uuid }).then((res) => {
            this.fetchAppliedJobCount();
            this.fetchCandidateAppliedJobs();
            this.fetchFavoJobCount();
          });
          this.isApplyingToJob = false;
        } else if (this.isUnFavJob) {
          let favo_id = this.favoId;
          const preferred_type = "favourite";
          if (favo_id) {
            this.deletePreferredJob({
              job_id,
              job_uuid,
              preferred_id: favo_id,
              preferred_type,
            }).then((res) => {
              this.fetchFavoJobCount();
            });
            this.isUnFavJob = false;
          }
        } else if (isWithdrawJob) {
          this.withdrawJob({ job_id, job_uuid }).then((res) => {
            this.fetchAppliedJobCount();
          });
          this.isWithdrawJob = false;
        }
      } else {
        this.isApplyingToJob = false;
        this.isUnFavJob = false;
        this.isWithdrawJob = false;
      }
      this.successModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
.center {
  display: flex;
  justify-content: center;
}
</style>
