<template>
  <div class="home">
    <CTabs variant="tabs" :active-tab="0">
      <CTab title="Profile">
        <div class="mt-2">
          <CandidateListUserView :item="candidateProfile" />
        </div>
      </CTab>
      <CTab :title="`Jobs Applied (${getAppliedCount})`">
        <AppliedJobs />
      </CTab>
      <CTab
        :title="`Jobs Withdrawn (${getWithdrawCount})`"
        v-if="getWithdrawCount"
      >
        <WithdrawJobs />
      </CTab>
      <CTab :title="`Favourite Jobs (${getFavoCount})`">
        <FavouriteJobs />
      </CTab>
      <CTab :title="`Messages (${getUnreadMessages.length})`">
        <EmailMessages
          v-if="candidateProfile"
          :email="candidateProfile.email"
          :candidate_uid="candidateProfile.candidate_uid"
        />
      </CTab>
    </CTabs>
    <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
      <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
    </CToaster>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CandidateListUserView from "@/containers/CandidateList/CandidateListUserView";
import AppliedJobs from "@/containers/CandidateProfile/AppliedJobs";
import FavouriteJobs from "@/containers/CandidateProfile/FavouriteJobs";
import EmailMessages from "@/containers/CandidateProfile/EmailMessages";
import WithdrawJobs from "@/containers/CandidateProfile/WithdrawJobs";

export default {
  components: {
    CandidateListUserView,
    AppliedJobs,
    FavouriteJobs,
    EmailMessages,
    WithdrawJobs,
  },
  data() {
    return {
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      isModalOpen: false,
      facilityData: {},
      isContactModalOpen: false,
      isEditModalOpen: false,
      isUserModalOpen: false,
      fields: [
        { key: "job_id", label: "Job ID" },
        { key: "facility_name", label: "Facility Name" },
        { key: "country", label: "Country" },
        { key: "location", label: "Location" },
        { key: "level", label: "Level" },
        { key: "contract_type", label: "Contract Type" },
        { key: "applied_on", label: "Applied on" },
        { key: "current_status", label: "Current Status" },
        { key: "linked_agency", label: "Linked Agency" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "candidateProfile",
      "getUserId",
      "getUserDetails",
      "getCustomerId",
      "getAppliedJobsCount",
      "getFavoJobsCount",
      "getCandidateEmailMessages",
      "getWithdrawJobsCount",
      "getCandidateWithdrawJobs",
    ]),
    getFavoCount() {
      this.fetchFavoJobCount();
      return this.getFavoJobsCount;
    },
    getAppliedCount() {
      this.fetchAppliedJobCount();
      return this.getAppliedJobsCount;
    },
    getUniqConversation() {
      let array = [];
      array = _.uniqBy(this.getCandidateEmailMessages, (v) =>
        [
          v.sender,
          v.subject.split(": ")[1] ? v.subject.split(": ")[1] : v.subject,
        ].join()
      );
      return this.getCandidateEmailMessages.length ? array : [];
    },
    getUnreadMessages() {
      return this.getUniqConversation
        ? _.filter(this.getUniqConversation, (message) => {
            if (message.direction === "O" && !message.read) {
              return message;
            }
          })
        : [];
    },
    getWithdrawCount() {
      return this.getWithdrawJobsCount;
    },
  },
  methods: {
    ...mapActions([
      "fetchAppliedJobCount",
      "fetchFavoJobCount",
      "fetchWithdrawJobCount",
      // "getConfigurableFieldsForRegistration",
      // "changeConfigurableFieldByCandidateType"
    ]),
    goToJobBoard() {
      this.$router.push({ path: "/jobs-list" });
    },
  },
  async mounted() {
    await this.$store.dispatch("getUserMe", { customer: this.getCustomerId });
    let { customer_uid, organisation_id, candidate_type_id } =
      this.candidateProfile;
    let payload = { customer_uid, organisation_id };
    this.fetchWithdrawJobCount();
    // await this.getConfigurableFieldsForRegistration(payload);
    // this.changeConfigurableFieldByCandidateType(candidate_type_id);
  },
  updated() {
    this.getAppliedCount;
    this.getFavoCount;
  },
};
</script>