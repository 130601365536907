<template>
  <div class="pl-2">
    <CModal
      color="primary"
      :show.sync="isShowModal"
      size="lg"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h5>Preview Job</h5>
        <button aria-label="Close" class="close" @click="onCancelPreview()">
          x
        </button>
      </template>
      <JobPreview :jobDetails="getSelectedJobBoardAdvert"></JobPreview>
      <template #footer>
        <div>
          <CButton color="primary" @click="onCancelPreview()">Cancel</CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import JobPreview from "@/components/JobDetailPreview/JobDetailPreviewUpdated.vue";

export default {
  name: "jobCardPreviewModal",
  props: ["isShowPopup", "onCancelPreview"],
  components: {
    JobPreview,
  },
  data() {
    return {
      isShow: this.isShowPopup,
    };
  },
  watch: {
    isShowPopup(newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    ...mapGetters(["getSelectedJobBoardAdvert"]),
    isShowModal: {
      get() {
        return this.isShow;
      },
      set(isShowPopup) {
        this.isShow = isShowPopup;
      },
    },
  },
};
</script>
