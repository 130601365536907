<template>
  <div>
    <div class="desktop-view">
      <div
        class="
          border
          col-lg-11 col-md-11
          container-md
          justify-content-center
          mt-5
        "
      >
        <CDataTable
          :striped="true"
          :responsive="true"
          :small="true"
          :items="getItems"
          :fields="fields"
          :items-per-page="5"
          sorter
          pagination
        >
          <template #preview="{ item }">
            <td class="py-2">
              <a
                class="hand-icon"
                @click="previewJob(item)"
                :style="'cursor:pointer'"
              >
                <i class="fas fa-eye ml-3" v-c-tooltip="'Preview Job'"></i>
              </a>
            </td>
          </template>
          <!-- <template #action="">
            <td class="py-2">
              <CButton class="delete-icon"
                ><i class="fas fa-trash-alt text-danger"
              /></CButton>
            </td>
          </template> -->
        </CDataTable>
      </div>
    </div>
    <div class="mobile-view">
      <div v-if="getItems" class="multi-records">
        <div
          class="top-border d-flex justify-content-between p-2"
          v-for="(item, index) in getItems"
          :key="index"
        >
          <CRow
            class="d-flex"
            style="justify-content: space-between; margin-left: 5px"
          >
            <div class="table col col-5">
              <p class="head">Job ID</p>
              <p class="data">{{ item.job_display_uid }}</p>
            </div>
            <div class="table col col-5">
              <p class="head">Job title</p>
              <p class="data">
                {{ item.job_title }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Facility Name</p>
              <p class="data">
                {{ item.facility_name }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Location</p>
              <p class="data">
                {{ item.location }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Country</p>
              <p class="data">
                {{ item.country }}
              </p>
            </div>
            <div class="table col col-10">
              <p class="head">comment</p>
              <p class="data">
                {{ item.comment }}
              </p>
            </div>
            <div class="table col col-5">
              <CButton color="primary" class="pl-8" @click="previewJob(item)">
                Preview
              </CButton>
            </div>
            <!-- <div class="table col col-5">
              <CButton color="primary" class="pl-8"> Delete </CButton>
            </div> -->
          </CRow>
        </div>
        <div v-if="!getItems.length">
          <p
            class="pt-3 text-center"
            :style="{ background: '#cccccc', height: '50px' }"
          >
            No items <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
          </p>
        </div>
      </div>
    </div>
    <JobCardPreviewModal
      v-if="previewModal"
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
export default {
  name: "Withdrawjobs",
  components: {
    JobCardPreviewModal,
  },
  data() {
    return {
      jobID: null,
      successModal: false,
      modalTitle: "",
      modalMessage: "",
      previewModal: false,
      sorter: { external: true, resetable: true },
      fields: [
        // { key: "job_id", label: "Job ID", _style: "width:7%" },
        { key: "job_id", label: "Job ID", _style: "width:7%" },
        { key: "job_title", label: "Job Title", _style: "width:15%" },
        { key: "facility_name", label: "Facility Name", _style: "width:17%" },
        { key: "location", label: "Location", _style: "width:10%" },
        { key: "country", label: "Country", _style: "width:12%" },

        { key: "preview", label: "Preview", _style: "width:5%" },
        { key: "withdrawal_on", label: "Withdrawal on", _style: "width:10%" },
        { key: "comment", label: "Comments", _style: "width:10%" },
        // { key: "action", label: "Action", _style: "width:10%" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCandidateWithdrawJobs", "getRoleScopes"]),
    getItems() {
      return this.getCandidateWithdrawJobs?.length
        ? this.getCandidateWithdrawJobs.map((val) => ({
            job_uuid: val?.job?.job_uuid,
            job_id: val?.job_id || "--",
            job_display_uid: val?.job?.display_uid || "--",
            customer_id: val?.job?.customer_uid,
            job_title: val?.job?.job_title || "--",
            facility_name: val?.job?.parent_org_info_required
              ? val?.job?.parent_org_info[0].name
              : val?.job?.organisation_name ||
                val?.job?.organisation.name ||
                "--",
            location:
              val?.job?.location_name || val?.job?.location?.location || "--",
            country:
              val?.job?.country_name ||
              val?.job?.location?.country?.country_name ||
              "--",
            comment: "Withdraw successful",
            withdrawal_on: moment
              .utc(val?.withdrawal_on)
              .local()
              .format("DD-MMM-YYYY h:mm A"),
          }))
        : [];
    },
  },
  mounted() {
    this.fetchCandidateWithdrawJobs();
  },
  methods: {
    ...mapActions([
      "fetchJobBenefitsAdvertDescByJobId",
      "fetchCandidateWithdrawJobs",
      "fetchCandidatePreferredJobs",
      "fetchWithdrawJobCount",
      "fetchFavoJobCount",
    ]),
    previewJob(job) {
      if (this.getRoleScopes && this.getRoleScopes.length) {
        this.fetchJobBenefitsAdvertDescByJobId({ job_uuid: job?.job_uuid });
        this.previewModal = true;
      }
    },
    onCancelPreview() {
      this.previewModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>